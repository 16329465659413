import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import HeroInner from "../components/heroinner"
import Features from "../components/features"

const Services = () => (
  <Layout>
    <SEO title="Services" description="Read More Info about Services and Process for our Web Development Oklahoma & search engine Optimization agency in Oklahoma" />
    <HeroInner title="Services"/>
    <Features />
  </Layout>
)

export default Services;